import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";

import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";
import {
  mdiAccount,
  mdiAccountArrowRight,
  mdiAccountCheck,
  mdiAccountClock,
  mdiAccountRemove,
  mdiAlertCircle,
  mdiArrowRight,
  mdiCameraOutline,
  mdiCheckCircle,
  mdiDelete,
  mdiDotsVertical,
  mdiEye,
  mdiEyeOff,
  mdiLockOutline,
  mdiMagnify,
  mdiMenu,
  mdiPencil,
  mdiSkipForward,
} from "@mdi/js";
import { en, it } from "vuetify/locale";
import sentry from "@/services/sentry.js";

// Vite emits vite:preloadError event when it fails to load dynamic imports.
window.addEventListener("vite:preloadError", () => {
  window.location.reload();
});

const app = createApp(App);

sentry.init(app, router);

app.use(createPinia());
app.use(router);

const vuetify = createVuetify({
  locale: {
    locale: "it",
    fallback: "en",
    messages: { it, en },
  },
  components,
  directives,
  icons: {
    defaultSet: "mdi",
    aliases: {
      ...aliases,
      dotsVertical: mdiDotsVertical,
      delete: mdiDelete,
      pencil: mdiPencil,
      lockOutline: mdiLockOutline,
      account: mdiAccount,
      eye: mdiEye,
      eyeOff: mdiEyeOff,
      arrowRight: mdiArrowRight,
      menu: mdiMenu,
      cameraOutline: mdiCameraOutline,
      checkCircle: mdiCheckCircle,
      alertCircle: mdiAlertCircle,
      magnify: mdiMagnify,
      skipForward: mdiSkipForward,
      accountArrowRight: mdiAccountArrowRight,
      accountCheck: mdiAccountCheck,
      accountRemove: mdiAccountRemove,
      accountClock: mdiAccountClock,
    },
    sets: {
      mdi,
    },
  },
});
app.use(vuetify);

app.mount("#app");
